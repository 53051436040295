import { AddNewHaulierVariables, AddNewHaulier, UpsertHaulierInput, ElasticSearchPage, HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges } from '../../../../../custom_typings/graphql'
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel'
import { bindable } from 'aurelia-framework';
import { createPageUpdateChecker } from '../../../../utility/graphqlHelpers';
import gql from 'graphql-tag'
import './create-haulier-modal.css';

export class CreateHaulierModal extends GraphQLBaseViewModel<void, void, void> {

    createNewHaulierDefault: Partial<UpsertHaulierInput> = { dataSource: undefined, reference: '', name: '', shortName: '', buid: undefined }
    haulierToEdit: Partial<UpsertHaulierInput> = {...this.createNewHaulierDefault }

    @bindable() existingHaulier: HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges | undefined
    @bindable() done: Function;

    bind(){
        this.haulierToEdit = this.existingHaulier ? 
            { ...this.existingHaulier, buid: this.existingHaulier.buid?.id.toString() } : 
            this.createNewHaulierDefault;
    }

    cancelCreateHaulier() {
        this.done();
    }

    errorContent: string | undefined;
    async persistNewHaulier() {

        let input = this.haulierToEdit;
        if(!input.buid || !input.dataSource || !input.name || !input.reference || !input.shortName) return;
        this.errorContent = undefined;
        let sanitizedInput: UpsertHaulierInput = { buid: input.buid, dataSource: input.dataSource, name: input.name, reference: input.reference, shortName: input.shortName };

        const query = gql`
            mutation AddNewHaulier($input: UpsertHaulierInput!, $haulierId: Int) {
                upsertHaulier(input: $input, haulierId: $haulierId) {
                    code
                    success
                    message
                }
            }
        `

        const pageChecker = await createPageUpdateChecker(ElasticSearchPage.HaulierManager)
        const result = await this.runQuery<AddNewHaulier, AddNewHaulierVariables>(
            query, 
            { 
                input: sanitizedInput, 
                haulierId: this.existingHaulier?.haulierId 
            })

        if(!result.upsertHaulier.success) {
            this.errorContent = result.upsertHaulier.message;
            throw new Error(result.upsertHaulier.message)
        }
        await pageChecker()

        await this.revalidateAllActiveQueries();
        this.done();
    }
}