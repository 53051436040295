import { ElasticSearchPage, UpsertMultipleHaulierInput, EditMultipleHauliers, EditMultipleHauliersVariables } from '../../../../../custom_typings/graphql'
import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel'
import { bindable, observable } from 'aurelia-framework';
import { createPageUpdateChecker } from '../../../../utility/graphqlHelpers';
import gql from 'graphql-tag'
import './multi-edit-haulier.css'

export class MultiEditHaulier extends GraphQLBaseViewModel<void, void, void> {

    haulierToEdit: { buid?: string, dataSource?: string } = {}
    @observable({ changeHandler: 'editingChanged' }) editingDataSource = false;
    @observable({ changeHandler: 'editingChanged' }) editingBuid = false;

    @bindable() haulierIds: number[]
    @bindable() done: Function;

    cancelEdit() {
        this.done();
    }

    editingChanged(){
        if(!this.editingDataSource) {
            this.haulierToEdit.dataSource = undefined;
        }
        if(!this.editingBuid) {
            this.haulierToEdit.buid = undefined;
        }
    }

    errorContent: string | undefined;
    async persistHauliers() {
        this.errorContent = undefined;
        let input: UpsertMultipleHaulierInput = { 
            buid: this.haulierToEdit.buid, 
            dataSource: this.haulierToEdit.dataSource,
            haulierIds: this.haulierIds
        };

        const query = gql`
            mutation EditMultipleHauliers($input: UpsertMultipleHaulierInput!) {
                upsertMultipleHauliers(input: $input) {
                    code
                    success
                    message
                }
            }
        `

        const pageChecker = await createPageUpdateChecker(ElasticSearchPage.HaulierManager)
        const result = await this.runQuery<EditMultipleHauliers, EditMultipleHauliersVariables>(query, { input })
        if(!result.upsertMultipleHauliers.success) {
            this.errorContent = result.upsertMultipleHauliers.message;
            throw new Error(result.upsertMultipleHauliers.message)
        }
        
        await pageChecker()

        await this.revalidateAllActiveQueries();
        this.done();
    }
}