import { customElement, bindable, computedFrom } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../../../pages/common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import { HaulierDataSourceDropdownQuery } from '../../../../../custom_typings/graphql'

@customElement('haulier-datasource-dropdown')
export class HaulierDatasourceDropdown extends GraphQLBaseViewModel<void, HaulierDataSourceDropdownQuery, void> {
  @bindable() selected: string | undefined;
  @bindable() disabled: boolean | undefined;
  @bindable() itemClicked:
    | undefined
    | ((params: { value: string; text: string }) => void);
  @bindable() required: boolean;

  
  @computedFrom('data', 'selected')
  get items(){
    if(!this.data) return []
    const dataValues = this.data.refFilterValues.map(g => g.key)
    if(!this.selected) return dataValues
    return Array.from(new Set([ ...dataValues, this.selected]))
  }

  query = gql`
    query HaulierDataSourceDropdownQuery {
        refFilterValues(property: "datasource", page: HaulierManager) {
            key
            label
            instances
        }
    }
  `;
}
