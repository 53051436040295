import { gql } from 'graphql-request'
import { ColumnKeyAndTitle, DeleteHauliers, DeleteHauliersVariables, ElasticSearchPage, GenerateHaulierExcelQuery, GenerateHaulierExcelQueryVariables, HaulierManagerListIdsQuery, HaulierManagerListIdsQueryVariables, HaulierManagerQuery, HaulierManagerQueryVariables, HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges, HaulierSortEnum, SortDirection } from '../../../custom_typings/graphql';
import { GraphQLInfiniteListViewModel, WithoutPagination } from '../common/GraphQLInfiniteListViewModel'
import { Grid, IGridColumn, IGridSelectableRowsConfig } from '../../components/grid/grid'
import { LocalSettings } from '../../services/localSettingsService';
import './hauliermanager.css';
import { autoinject, computedFrom } from 'aurelia-framework';
import { ensureNumber, isEmpty, isTouchDeviceWatcher, removeNoneFromArray } from '../../utility';
import { I18N } from 'aurelia-i18n';
import { BlobService } from '../../services';
import { createPageUpdateChecker } from '../../utility/graphqlHelpers';

const defaultSelectedColumns = [
    HaulierSortEnum.Source,
    HaulierSortEnum.Reference,
    HaulierSortEnum.Name,
    HaulierSortEnum.ShortName,
    HaulierSortEnum.Buid,
    HaulierSortEnum.HaulierTag,
    'edit'
];

@autoinject()
export class HaulierManager extends GraphQLInfiniteListViewModel<HaulierManagerQuery, HaulierManagerQueryVariables, HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges> {

    constructor(private i18n: I18N, private blobService: BlobService) {
        super(HaulierManager.name, e => e.elasticSearchPages.haulierManager.data.totalCount, e => e.elasticSearchPages.haulierManager.data.edges)
        this.unsubscribeOnDetach(isTouchDeviceWatcher(isTouchDevice => {
            this.isTouchDevice = isTouchDevice
          }))
    }

    isTouchDevice: boolean;

    freshnessPageToCheck = ElasticSearchPage.HaulierManager;

    loadNewData() {
        this.revalidatePageActiveQueries()
    }

    query = gql`
        query HaulierManagerQuery($first: Int!, $offset: Int!, $freeTextQuery: [String!], $sortProperty: HaulierSortEnum!, $sortDirection: SortDirection!) {
            elasticSearchPages {
                haulierManager (freeTextQuery: $freeTextQuery, sortProperty: $sortProperty, sortDirection: $sortDirection) {
                    data(first: $first, offset: $offset) {
                        totalCount
                        edges {
                            haulierId
                            dataSource
                            reference
                            name
                            shortName
                            haulierTag
                            buid {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    `;

    clearFilters() {
        this.variables = this.defaultvariables
    }

    defaultvariables: WithoutPagination<HaulierManagerQueryVariables> = {
        sortDirection: SortDirection.Asc,
        sortProperty: HaulierSortEnum.Name,
        freeTextQuery: []
    }

    variables = this.defaultvariables

    @computedFrom('variables.freeTextQuery')
    get freeTextQuery() {
        return this.variables?.freeTextQuery?.join('\n')
    }

    freeTextQueryChanged (newValue: string = '') {
        newValue = newValue?.trim();
        const oldValue = this.freeTextQuery?.trim() || ''
        if(oldValue === newValue?.trim()) return;
        this.setNewTextQueryInVariables(newValue);       
    }

    async downloadExcel() {
        const query = gql`
          query GenerateHaulierExcelQuery(
            $sortProperty: HaulierSortEnum
            $sortDirection: SortDirection
            $freeTextQuery: [String!]
            $columnKeyAndTitle: [ColumnKeyAndTitle!]!
          ) {
          elasticSearchPages {
            haulierManager (
              sortProperty: $sortProperty
              sortDirection: $sortDirection
              freeTextQuery: $freeTextQuery
            ) {
                excelSheet(columnKeyAndTitle: $columnKeyAndTitle)
              }
            }
          }
        `;
    
        const variables: GenerateHaulierExcelQueryVariables = { 
          ...this.variables, 
          columnKeyAndTitle: removeNoneFromArray(this.selectedColumns.filter(a => a !== 'edit').map(s => this.columns.find(c => c.columnKey === s))).map<ColumnKeyAndTitle>(column => ({
            key: column.columnKey,
            title: this.i18n.tr(column.columnTitle)
          }))
        };
    
        try {
          const { promise } = this.runQuery<
            GenerateHaulierExcelQuery,
            GenerateHaulierExcelQueryVariables
          >(query, variables);
          const excelSheet = await promise;
    
          await this.blobService.downloadFileFromUrl(
            excelSheet.elasticSearchPages.haulierManager.excelSheet,
            'Hauliers.xlsx'
          );
        } catch (error) {
          throw new Error('Could not download excel');
        }
      }
  
    setNewTextQueryInVariables(newValue = '') {
      this.variables = {
          ...this.variables,
          freeTextQuery: newValue.split('\n').filter(e => !isEmpty(e)).map(f => f?.trim())
      }
    }

    columns: IGridColumn<HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges>[] = [
        {
            columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_Source',
            columnKey: HaulierSortEnum.Source,
            columnHovered: column => this.preloadSort(column.columnKey),
            sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
            property: e => e.dataSource,
            width: 200
        },
        {
            columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_Reference',
            columnKey: HaulierSortEnum.Reference,
            columnHovered: column => this.preloadSort(column.columnKey),
            sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
            property: e => e.reference,
            width: 200
        },
        {
            columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_Name',
            columnKey: HaulierSortEnum.Name,
            sortedByInitially: 'asc',
            columnHovered: column => this.preloadSort(column.columnKey),
            sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
            property: e => e.name,
            width: 200
        },
        {
            columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_ShortName',
            columnKey: HaulierSortEnum.ShortName,
            columnHovered: column => this.preloadSort(column.columnKey),
            sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
            property: e => e.shortName,
            width: 200
        },
        {
            columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_BUID',
            columnKey: HaulierSortEnum.Buid,
            columnHovered: column => this.preloadSort(column.columnKey),
            sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
            property: e => e.buid?.name,
            width: 200
        },
        {
          columnTitle: 'UI_HaulierManager_HaulierList_Table_Headers_HaulierTag',
          columnKey: HaulierSortEnum.HaulierTag,
          columnHovered: column => this.preloadSort(column.columnKey),
          sort: (_list, _order, column) => this.sortByProperty(column.columnKey),
          property: e => e.haulierTag,
          width: 200
      },
      {
        columnTitle: 'UI_Common_Edit',
        columnKey: 'edit',
        type: 'icon',
        property: () => 'fa-pencil',
        clickHandler: (row: HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges) => {
          this.editExistingHaulier = row;
        },
        width: 100
      }
    ]

    editExistingHaulier: HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges | undefined;
    
    selectedHaulierIds: string[] = []
    selectableRowsConfig: IGridSelectableRowsConfig<HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges> = {
      rowKey: e => [e.haulierId],
      selectText: 'UI_HaulierManager_SelectionBar_Description',
      selectedRowsChanged: (selectedRows: string[]) => {
        this.selectedHaulierIds = selectedRows;
      },
      fetchAllIds: this.fetchAllIds.bind(this)
    }

    abortController: AbortController | undefined;
    async fetchAllIds() {
      if (this.abortController) this.abortController.abort();

      const query = gql`
        query HaulierManagerListIdsQuery(
          $freeTextQuery: [String!], 
          $sortProperty: HaulierSortEnum!, 
          $sortDirection: SortDirection!
        ) {
          elasticSearchPages {
            haulierManager (
            freeTextQuery: $freeTextQuery
            sortProperty: $sortProperty
            sortDirection: $sortDirection
          ) {
              data {
                edges {
                  haulierId
                }
              }
            }
          }
        }
      `;
      const variables = this.variables;
      const { promise, abortController } = this.runQuery<
      HaulierManagerListIdsQuery,
      HaulierManagerListIdsQueryVariables
      >(query, variables);
      this.abortController = abortController;

      try {
        const ids = await promise;
        this.abortController = undefined;
        return ids.elasticSearchPages.haulierManager.data.edges.map(s => s.haulierId);
      } catch (error) {
        if (abortController?.signal.aborted) return undefined;
        throw error;
      }
    }

    gridviewmodel: Grid<HaulierManagerQuery_elasticSearchPages_haulierManager_data_edges>;
    deleteConfirmation = false;
    async deleteSelectedHauliers() {
        const query = gql`
          mutation DeleteHauliers($haulierIds: [Int!]!) {
            deleteHauliers(haulierIds: $haulierIds)
          }
        `

        const pageChecker = await createPageUpdateChecker(ElasticSearchPage.HaulierManager)
        await this.runQuery<DeleteHauliers, DeleteHauliersVariables>(query, { haulierIds: this.selectedHaulierIds.map(ensureNumber) })
        await pageChecker();
        await this.revalidateAllActiveQueries();
        this.gridviewmodel.clearSelectedRows();
        this.deleteConfirmation = false;
    }

    createNewHaulier = false;
    
    closeCreateNewHaulierModal(){
      this.createNewHaulier = false;
      this.editExistingHaulier = undefined;
    }

    @computedFrom('freeTextQuery')
    get rowsInTextArea() {
        const numberOfNewlines = (this.freeTextQuery && this.freeTextQuery.match(/\n/g)?.length) || 0;
        return Math.min(4, numberOfNewlines + 1);
    }

    selectedColumns = LocalSettings.getSettingParsed(
        'haulierManagerSelectedColumns',
        defaultSelectedColumns
      );
    
    persistColumns(selectedColumns: HaulierSortEnum[]) {
        this.selectedColumns = selectedColumns;
        LocalSettings.setSetting(
            'haulierManagerSelectedColumns',
            JSON.stringify(this.selectedColumns)
        );
    }

    sortByProperty(sortProperty: HaulierSortEnum) {
        this.variables = {
          ...this.variables,
          sortProperty: sortProperty,
          sortDirection:
            sortProperty === this.variables.sortProperty
              ? this.variables.sortDirection === SortDirection.Asc
                ? SortDirection.Desc
                : SortDirection.Asc
              : SortDirection.Asc
        };
      }
    
      preloadSort(sortProperty: HaulierSortEnum) {
        this.preloadQuery<
          HaulierManagerQuery,
          HaulierManagerQueryVariables
        >(this.query, {
          sortProperty: sortProperty,
          sortDirection:
            sortProperty === this.variables.sortProperty
              ? this.variables.sortDirection === SortDirection.Asc
                ? SortDirection.Desc
                : SortDirection.Asc
              : SortDirection.Asc,
          first: this.pageSize,
          offset: 0
        });
      }

}